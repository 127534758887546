import logo from './invicti_new_logo.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />

        {/* <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a> */}
      </header>
      <div>
      <p>
          <code>PARIS, FRANCE +33756887576 - SILICON VALLEY, US +16504888487</code>
        </p>
      </div>
     
    </div>
  );
}

export default App;
